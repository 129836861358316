.logo{
    width:40px;
}

.social-container{
    position: relative;
    z-index: 1;
}

.social-container ul{
    padding:0;
    position:fixed;
    bottom:0;
    left:20px;

}
.social-container li{
    display:block;
    margin:20px 0;

}

@media only screen and  (max-width: 600px)  {

    .logo{
        width:30px;
    }

    .social-container ul{
        padding:0;
        position:relative;
        bottom:0;
        top:30px;
        left:0px;

    
    }
    .social-container li{
        display:inline;
        margin:0px 20px;
    
    }
    
    }
