.App{
    background-color: #fcfcfc;
    height: 100%;
   
}

br {
    display: block;
    margin-bottom: 2px;
    font-size:2px;
    line-height: 2px;
  }

  a{text-decoration: none;
color:#474747;
}


a:hover{
  text-decoration: none;
  color:#474747;
}


  @media only screen and  (max-width: 600px)  {

h1{
    font-size: 30px;
}

}

