

.intro{
    text-align: center;  
    width: 70%;
    margin:0px auto;
    padding-top:200px;
    border-radius: 5px;
    
}


.name{
    color: #333333;
    font-weight:900;
    font-size:80px;
    position: relative;
    top:5px;
}



.frontend-word{
    font-style:italic;
    font-weight: bold;
    position: relative;
}

.button-word{
    font-weight: bolder;
    font-size:33px;
}

.trace1{
    position: absolute;
    left:-10px;
    top:80px;
}

.trace2{
 width:100%;
 position: absolute;
 top:37px;
 left:-5px;
}

.hide{
    display:block;
}


h1{
    font-size: 58px;
    font-weight: 100;
    margin: 20px 0;
    color:#474747;


}

h2{
    font-size: 33px;
    font-weight: 300;
    margin:0;
    color:#474747;
    line-height: 55px;;
    

}

.intro p{
    display:block;
    font-family: 'Fira Sans', sans-serif;
    font-size: 28px;
    font-style: italic;
    color:#474747;

}

input{
    font-family: 'Fira Sans', sans-serif;
    font-style:italic;
    font-weight: 900;
    color:#DDDDDD;
    font-size: 35px;
    background-color:#353535;
    padding: 10px 20px;
    border-radius: 8px;
    border:none;
    transition: all 400ms ease-in-out;

}

input:hover{
    cursor: pointer;
    color:#474747;
    background-color:#DDDDDD;
    

}




@media only screen and  (max-width: 600px)  {

   h1{
       font-size: 50px;
   }

   .trace1{
    position: absolute;
    left:-10px;
    top:60px;
}

   .name{
    color: #333333;
    font-weight:900;
    font-size:60px;
    position: relative;
    top:5px;
}

   .hide{
       display:none;
   }

   .intro{
    
    width: 80%;
    margin:0px auto;
    padding-top:0px;

   }

   input{
    margin: 20px 0;

}

  
    

}
