.MainPage{
    text-align: center;
    background-color: #fcfcfc;
    padding-bottom: 20px;;
    

}


.trace{
    position:absolute;
    top:60px;
    left:-5px;
    width:100%;
    
    }
    
    @media only screen and  (max-width: 600px)  {

        h1{
            font-size: 35px;
        }

        .trace{
            position:absolute;
            top:35px;
            left:-5px;
            width:100%;    
            }
        }
