.footer{
   background-color: #fcfcfc;
   font-style: normal;
   position: relative;
   text-align:center;
   margin:0 auto;
   padding:60px;
   font-size:17px;
   
}

.footer a{
   font-weight: 700;
}


@media only screen and  (max-width: 600px)  {


   .footer{
      padding:0px;
      margin:50px 0;
   }

}
