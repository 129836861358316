br {
	content: '';
	margin: 15px 0;
	display: block;
}

#about {
	text-align: center;
	width: 70%;
	margin: 0 auto;
	padding-top: 40px;
}

.about-me-container {
	align-items: center;
}

#about p {
	font-style: normal;
	text-align: justify;
	margin: 25px 0;
	font-size: 19px;
	line-height: 22px;
}

.profile-photo {
	width: 400px;
	border-radius: 40px;
	margin: 0 auto;
	opacity: 1;
	transition: All 300ms ease-in-out;
	padding: 0;
}

#about ul {
	text-align: center;
	padding: 0;
	margin: 40px 0;
}

#about li {
	display: block;
	margin: 0 20px;
	font-weight: 200;
	font-size: 25px;
	color: #474747;
}

.skills-container {
	text-align: center;
	margin: 25px auto;
	width: 85%;
}

.title {
	font-style: normal;
	font-weight: bold;
	position: relative;
	margin-left: 60px;
}

.hire-container {
	margin: 20px 0;
	text-align: center;
}

@media only screen and (max-width: 600px) {
	#about {
		text-align: center;
		width: 80%;
		margin: 0 auto;
		padding-top: 70px;
	}

	#about p {
		font-style: normal;
		text-align: justify;
		font-size: 19px;
		line-height: 22px;
	}

	.about-me-container {
		margin: 50px 0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
	}

	.title {
		font-style: normal;
		font-weight: bold;
		position: relative;
		margin-left: 0px;
	}
}
